<template>

    <b-modal :title="modalTitle" v-model="modalActive" size="lg" no-close-on-backdrop  @hide="clearData" >
        <b-overlay :show="showLoader">

            <template #default>
                <validation-observer ref="validation">
                    <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.pick_up_date')" rules="required">
                        <date-picker :locale="currentLocale" :max-date="new Date()" :first-day-of-week="2" v-model="addObject.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" is24hr hide-time-header mode="date" :is-required="true">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input class="form-control"
                                       :value="formatDate(inputValue)"
                                       v-on="inputEvents"
                                />
                            </template>
                        </date-picker>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                    <b-row class="mt-1">
                        <b-col cols="6">
                            <b-form-group :label="$t('form_data.packaging')" class="w-100">
                                <validation-provider #default="{ errors }"
                                                     :name="$t('general.add_ingredient')" rules="required">
                                    <v-select label="name" :filter="fuseSearch"  v-model="selected_packaging"
                                              appendToBody :options="newPackagingFiltered">
                                        <template #option="{ name, packaging_type_code }">
                                            <div >{{ packaging_type_code }} - {{ name }}</div>
                                        </template>

                                        <template #selected-option="{ name, packaging_type_code }">
                                            <div>{{ packaging_type_code }} - {{ name }}</div>
                                        </template>
                                    </v-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">

                            <b-form-group :label="$t('form_data.quantity')" class="w-100">
                                <div style="display: flex; width: 100%">
                                    <validation-provider style="width: 100%" #default="{ errors }"
                                                         :name="$t('form_data.quantity')" rules="required|min_number: 0">
                                        <b-form-input v-model="selected_quantity"/>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <div class="d-flex justify-content-center ">  
                        <b-button variant="primary" @click="addPackaging" class="w-25">
                            <feather-icon icon="ArrowDownIcon" class=" justify-content-center"/>
                        </b-button>
                    </div>

                    <div v-if="modalActive && arraysLoad && addObject.returnable_packaging_items">
                        <div>{{$t('form_data.packaging')}}</div>
                        <CustomTable  ref="packagingTable" class="mt-1" :fields="columnDefs" :items="addObject.returnable_packaging_items" :show-table-options="false">
                            <template #packaging_type_code="{item}">
                                {{item.packaging_type_code}}
                            </template>
                            <template #name="{item}">
                                {{item.name}}
                            </template>
                            <template #quantity="{item}">
                                {{item.quantity / 100}}
                            </template>
                            <template #actions="{item}">
                                <div class="d-flex justify-content-center">
                                    <b-button class="mr-1 m-0 button" variant="danger" size="sm" @click="removePackaging(item.returnable_packaging_type_id)">
                                        {{ $t('general.delete') }}
                                    </b-button>

                                </div>
                            </template>
                        </CustomTable>
                    </div>

                    <div class="w-100 my-2 d-flex">
                        <b-form-group :label="$t('table_fields.documentation')" class="w-100">
                            <b-input-group>

                                <b-form-file
                                    class="messageImageUpload"
                                    :placeholder="$t('keys.returnable_packaging.add_attachment')"
                                    :multiple="true"
                                    style="height: 40px;"
                                    ref="fileForm"
                                    @change="addImages"
                                >
                                    <template #file-name>
                                        <div>{{ $t('table_fields.documentation') }}</div>
                                    </template>
                                </b-form-file>
                                <b-input-group-append is-text >
                                    <feather-icon icon="FileIcon" />
                                </b-input-group-append>
                            </b-input-group>
                            <small v-if="fileSize >= 10" class="text-danger">{{ $t('general.invalid_file_size') }}</small>
                        </b-form-group>
                    </div>
                    <div v-if="loaded && joinedAttachments.length > 0">
                        <hr/>
                        <div style="font-size: 16px;">
                            <div class="d-flex justify-content-between" style="font-size: 14px; font-weight: bold; padding-bottom: 10px; padding-top: 10px;">
                                <div>
                                    {{ $t('print.message.attachments') }} ({{joinedAttachments.length}}):
                                </div>
                            </div>
                            <div class="mt-2"  style="display: flex; flex-wrap: wrap;">
                                <div style="font-size: 16px;">
                                    <div style="display: flex; flex-wrap: wrap;">
                                        <div class="mb-1 mr-1" style="position:relative;" v-for="(file, id) in joinedAttachments" :key="'slika_' + file.id" @mouseenter="hoveredItem = id" @mouseleave="hoveredItem = -1">
                                            <div class="mouseHoverAttachment" style="margin-left: 3px; margin-right: 3px; position: absolute; z-index: 1100; " v-if="hoveredItem === id" >
                                                <div style="height: 9rem; width: 12rem; cursor: pointer; overflow-y: hidden; overflow-x: hidden;">
                                                    <div class="d-flex">
                                                        <div style="padding: 8px; " class="d-flex justify-content-center pt-1">
                                                            <feather-icon :icon="getFileIcon(file)"/>
                                                        </div>
                                                        <div class="d-flex flex-column align-items-start" style="width: 100%; height: 8rem;">
                                                            <div class="cutText mb-auto" style="margin-top: 13px;">
                                                                {{ file.name }}
                                                            </div>
                                                            <div style="width: 100%; padding-bottom: 0;">
                                                                <div style="font-size: 12px; font-weight: bold; ">
                                                                    {{getFileSize(file.size)}}
                                                                </div>
                                                                <b-button variant="primary" style="width: 100%; height: 25px; padding: 0px;" >
                                                                    <div class="d-flex align-items-center justify-content-center" @click="getReturnablePackagingAttachment(file)">
                                                                        <feather-icon variant="primary" icon="DownloadIcon" style="cursor: pointer;"/>
                                                                    </div>
                                                                </b-button>
                                                                <div class="d-inline-flex justify-content-between w-100 mt-1">
                                                                    <b-button variant="warning" style="width: 100%; height: 25px; padding: 0;" @click="removeSelectedAttachment(file.id)">
                                                                        <div class="d-flex align-items-center justify-content-center">
                                                                            <feather-icon icon="TrashIcon" style="cursor: pointer;"/>
                                                                        </div>
                                                                    </b-button>
                                                                    <b-button variant="warning" style="width: 100%; height: 25px; padding: 0;" @click="openAttachments(id)" class="ml-1">
                                                                        <div class="d-flex align-items-center justify-content-center ">
                                                                            <feather-icon icon="GridIcon" style="cursor: pointer;"/>
                                                                        </div>
                                                                    </b-button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div style="padding: 14px; " class="d-flex justify-content-center pt-1">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="borderAttachment" style="margin-left: 3px; margin-right: 3px;">
                                                <div class="w-100" style="overflow: hidden; height: 7rem; width: 12rem!important;" v-if="file.type.includes('image')">
                                                    <img alt="" style="cursor: pointer; object-fit: cover; width: 100%; height: 100%;" :src="file.url" />
                                                </div>
                                                <div v-else-if="file.type === 'application/pdf'">
                                                    <div style="height: 7rem; width: 12rem;">
                                                        <pdf style="height: 100%; overflow: hidden;" :src="file.url" />
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div style="height: 7rem; width: 12rem; cursor: pointer; overflow-y: hidden;" class="d-flex justify-content-center align-items-center">
                                                        <feather-icon icon="FileIcon" size="50" color="#b7b7b7"/>
                                                    </div>
                                                </div>
                                                <div style="width: 100%;height: 2rem;" class="d-flex align-items-center attachmentShortInfo">
                                                    <div style="padding-left: 5px; padding-right: 5px; margin: 0px;">
                                                        <feather-icon :icon="getFileIcon(file)" style="cursor: pointer;"/>
                                                    </div>
                                                    <div class="cutTextOne" style="width: 8rem; margin: 0;">
                                                        {{ file.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </validation-observer>
                <image-display-modal-vue v-if="loaded" :attachments="joinedAttachments" ref="imageModal" />

            </template>
        </b-overlay>


        <template #modal-footer>
            <b-button variant="primary" @click="validate" :disabled="addObject.returnable_packaging_items.length ===0 || fileSize >= 10 || showLoader">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {
        BButton,
        BFormGroup,
        BFormInput,
        BModal,
        BRow,
        BCol,
        BFormFile,
        BInputGroupAppend,
        BInputGroup, BOverlay
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@core/utils/validations/validations'
    import * as Sentry from '@sentry/vue'
    import vSelect from 'vue-select'
    import {Tiers} from '@/libs/enums/Tiers'
    import {DatePicker} from 'v-calendar'
    import pdf from 'vue-pdf'
    import {ReturnablePackaging} from '@/libs/enums/ReturnablePackaging'
    import ImageDisplayModalVue from '@/views/SystemMessages/components/AttachmentsViewGallery.vue'
    import Fuse from 'fuse.js'
    import CustomTable from '@/components/CustomTable.vue'


    export default {
        components: {
            BOverlay,
            CustomTable,
            BInputGroup,
            BInputGroupAppend,
            ImageDisplayModalVue,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            ValidationProvider,
            ValidationObserver,
            vSelect,
            DatePicker,
            BRow,
            BCol,
            BFormFile,
            pdf
        },
        props: {
            productsRaw: {
                type: Array
            },
            vats:{
                type:Array
            }
        },
        data() {
            return {
                showLoader:false,
                returnablePackagingType: 0,
                arraysLoad: false,
                modalActive: false,
                addObject: {
                    timestamp:'',
                    returnable_packaging_items:[],
                    documentation:[]
                },
                selected_packaging:null,
                selected_quantity:0,
                allPackagingTypes:[],
                newPackagingFiltered:[],
                joinedAttachments: [],
                hoveredItem:0,
                attachmentsReady: false,
                attachmentsDownloaded: {},
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },

                required,
                editing: false,
                loaded:false
            }
        },
        computed:{
            fileSize() {
                let sum = 0
                this.joinedAttachments.forEach(x => {
                    sum = sum + x.size
                })

                const sizeInMB = sum / (1024 * 1024)

                return sizeInMB
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            },
            modalTitle() {
                return this.editing ? this.$t('keys.returnable_packaging.add_procurement') : this.$t('keys.returnable_packaging.add_procurement')
            },
            columnDefs() {

                const hasPermission =  this.$hasPermission(this.$permissions.ReturnablePackagingWrite)
                const defs = [
                    {
                        key: 'packaging_type_code',
                        label: this.$t('table_fields.code'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'name',
                        label: this.$t('form_data.packaging'),
                        filter: false,
                        permissions: true
                    },
                    {
                        key: 'quantity',
                        label:  this.$t('table_fields.quantity'),
                        filter: false,
                        permissions: true
                    }
                ]
                if (hasPermission) {
                    defs.push(
                        {
                            key: 'actions',
                            label: this.$t('table_fields.actions'),
                            permissions: true
                        }
                    )
                }

                return defs
            }
        },
        methods: {
            fuseSearch(options, search) {
                const fuse = new Fuse(options, {
                    keys: ['name', 'packaging_type_code'],
                    shouldSort: true
                })
                return search.length ? fuse.search(search).map(({item}) => item) : fuse.list
            },
            formatDate(inputDate) {
                return this.dayjs(inputDate).format('DD.MM.YYYY')
            },
            async addPackaging() {
                try {
                    const success = await this.$refs.validation.validate()

                    let tmp = this.selected_quantity

                    if (tmp) {
                        tmp = String(tmp).replace(',', '.')
                    }

                    if (success) {
                        const packaging = {
                            'returnable_packaging_type_id': this.selected_packaging.id,
                            'name': this.selected_packaging.name,
                            'packaging_type_code':this.selected_packaging.packaging_type_code,
                            'quantity': Number(tmp) * 100
                        }

                        this.addObject.returnable_packaging_items.push(packaging)
                        this.newPackagingFiltered = this.newPackagingFiltered.filter(ele => ele.id !== this.selected_packaging.id)

                        this.selected_packaging = null
                        this.selected_quantity = ''
                        this.$refs.validation.reset()
                    }
                } catch (err) {
                    this.$printError(this.$t('print.error.on_add'))
                }


            },
            open(data = null) {
                this.returnablePackagingType = ReturnablePackaging.Procurement
                this.loadReturnablePackagingTypes()
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                    this.loadMessageAttachments()

                } else {
                    this.editing = false
                    this.addObject = {
                        timestamp:new Date(),
                        returnable_packaging_items:[],
                        documentation:[]
                    }
                }


                this.modalActive = true
                this.loaded = true
            },
            validate() {

                if (this.editing) this.edit()
                else this.add()
                 
            },
            async add() {
                try {
                    this.showLoader = true
                    const images = await this.uploadFiles(this.joinedAttachments)

                    this.addObject.documentation = images
                    const response = await this.$http.put('/api/client/v1/returnable_packaging', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        timestamp:'',
                        returnable_packaging_items:[],
                        documentation:[]
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit() {
                try {
                    this.showLoader = true
                    const images = await this.uploadFiles(this.joinedAttachments)

                    this.addObject.documentation.push(...images)

                    await this.$http.put(`/api/client/v1/returnable_packaging/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        timestamp:'',
                        returnable_packaging_items:[],
                        documentation:[]
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                }
            },
            removePackaging(id) {
                for (let i = 0; i < this.addObject.returnable_packaging_items.length; i++) {
                    if (this.addObject.returnable_packaging_items[i].returnable_packaging_type_id === id) {
                        this.newPackagingFiltered.push(this.allPackagingTypes.filter((ingredient) => {
                            return ingredient.id === id
                        })[0])
                        this.addObject.returnable_packaging_items.splice(i, 1)
                        break
                    }
                }
            },
            openAttachments(id) {
                this.hoveredItem = -1
                this.$refs.imageModal.open(id)
            },
            async loadReturnablePackagingTypes() {

                if (!this.$hasTierPermission(Tiers.Premium)) {
                    return
                }

                try {

                    this.showLoader = true

                    const response = await this.$http.get('/api/client/v1/returnable_packaging/type')
                    this.allPackagingTypes = response.data.items ?? []
                    this.newPackagingFiltered = this.allPackagingTypes.filter((ingredient) => {
                        return !this.addObject.returnable_packaging_items.some((addedIngredient) => addedIngredient.returnable_packaging_type_id === ingredient.id)
                    })

                    this.arraysLoad = true

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            makeId(length) {
                let result = ''
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                const charactersLength = characters.length
                let counter = 0
                while (counter < length) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength))
                    counter += 1
                }
                return result
            },
            async addImages(event) {

                const files =  event.target.files

                this.hoveredItem = -1

                for (const file of files) {
                    const newId = this.makeId(30)
                    if (this.joinedAttachments.findIndex(ele => ele.name === file.name) === -1) {
                        await file.arrayBuffer().then((arrayBuffer) => {
                            const blob = new Blob([new Uint8Array(arrayBuffer)], {type: file.type})
                            const url = window.URL.createObjectURL(blob)
                            this.joinedAttachments.push({
                                url,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                uploaded: false,
                                id: newId,
                                file
                            })
                        })
                    }
                }
            },
            removeSelectedAttachment(id) {
                this.joinedAttachments = this.joinedAttachments.filter(ele => ele.id !== id)
                this.addObject.documentation = this.addObject.documentation.filter(ele => ele !== id)
            },
            async uploadFiles(files) {
                const promises = []

                for (const image of files) {
                    if (image.uploaded) {
                        continue
                    }

                    const formData = new FormData()
                    formData.append('file', image.file)
                    const loadPromise = this.$http.post('/api/client/v1/returnable_packaging/attachments/procurement', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    promises.push(loadPromise)
                }

                const images = new Array(promises.length)

                const results = await Promise.all(promises)
                for (const index in results) {
                    const res = results[index]
                    if (res.status === 200) {
                        images[index] = res.data.image_id
                    }
                }

                return images
            },
            getFileIcon(file) {
                try {
                    switch (file.type.split('/')[0]) {
                    case 'image':
                        return 'ImageIcon'
                    case 'audio':
                        return 'HeadphonesIcon'
                    case 'application':
                        if (file.extension === 'pdf') return 'FileTextIcon'
                        else return 'PackageIcon'
                    case 'video':
                        return 'FilmIcon'
                    default:
                        return 'PackageIcon'
                    }
                } catch {
                    this.$printError('Prišlo je do napake!', 3000)
                }

            },
            getFileSize(val) {
                let em = 'B'
                let size = val

                if (size > 1024) {
                    size = size / 1024
                    em = 'KB'
                }

                if (size > 1024) {
                    size = size / 1024
                    em = 'MB'
                }

                if (size > 1024) {
                    size = size / 1024
                    em = 'GB'
                }

                size = Math.round(size * 100) / 100

                return `${size} ${em}`
            },
            clearData() {
                this.joinedAttachments = []
                this.addObject = {
                    timestamp:'',
                    returnable_packaging_items:[],
                    documentation:[]
                }

                this.$emit('close')
            },
            async getReturnablePackagingAttachment(doc) {
                try {
                    this.show = true

                    const link = document.createElement('a')

                    if (!doc.org_url) {
                        link.href = doc.url
                    } else {
                        link.href = doc.org_url
                    }

                    link.setAttribute('download', `${doc.name}`) //or any other extension

                    document.body.appendChild(link)
                    link.click()

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'), 3000)
                } finally {
                    this.show = false
                }
            },
            async loadMessageAttachments() {
                try {
                    const res = await this.$http.get(`/api/client/v1/returnable_packaging/attachments/procurement/${this.addObject.id}`)
                    const downloadedImages = res.data.attachments ?? []
                    for (const downloadedImage of downloadedImages) {
                        const attachmentResp = await this.$http.get(`/api/client/v1/returnable_packaging/attachment_files/procurement/${downloadedImage.minio_id}`, {responseType: 'blob'})
                        const url = window.URL.createObjectURL(new Blob([attachmentResp.data]))


                        if (downloadedImage.file_type === 'application/pdf') {
                            const pdf_src = await pdf.createLoadingTask(url)
                            this.joinedAttachments.push({
                                url: pdf_src,
                                name: downloadedImage.name,
                                id: downloadedImage.id,
                                type: downloadedImage.file_type,
                                size: downloadedImage.byte_size,
                                uploaded: true
                            })
                        } else {
                            this.joinedAttachments.push({
                                url,
                                name: downloadedImage.name,
                                id: downloadedImage.id,
                                type: downloadedImage.file_type,
                                size: downloadedImage.byte_size,
                                uploaded: true
                            })
                        }
                    }

                } catch {
                    this.$printError('Prišlo je do napake!')
                }
            }
        }
    }
</script>
<style scoped lang="scss">

.light-layout {
  .mouseHoverAttachment{
    background: #d9d9d9;
    border: 1px solid #d9d9d9;
    color: #646464;
  }

  .attachmentShortInfo{
    background: #d9d9d9;
    border: #d9d9d9;
    color: #2a2e30;
  }

  .borderAttachment{
    border: 2px solid #d9d9d9 !important;
  }
}

.dark-layout {
  .mouseHoverAttachment{
    background: #7c7c7c;
    border: 1px solid #7c7c7c;
    color: #2a2e30;
  }

  .attachmentShortInfo{
    background: #7c7c7c;
    border: #7c7c7c;
    color: #2a2e30;
  }

  .borderAttachment{
    border: 2px solid #7c7c7c !important;
  }
}

.textMedium{
  font-size: 18px;
  font-weight: bold;
}

.cutText{
  margin-top: 5px;
  font-weight: bold;
  font-size: 13px;
  padding-right: 5px;
  overflow: hidden;
  word-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  line-height: 1em;
  height: 3em;
  -webkit-box-orient: vertical;
}

.cutTextOne{
  margin-top: 5px;
  font-weight: bold;
  font-size: 13px;
  padding-right: 5px;
  overflow: hidden;
  word-wrap: anywhere;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  line-height: 1em;
  -webkit-box-orient: vertical;
}

.dark-layout .open-attachment{
  background: #292929!important;
}

.open-attachment{
  background: #f8f8f8!important;
}

</style>


<style>
.messageImageUpload .custom-file-label::after{
  display: none !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.productsModal .modal-dialog .modal-content {
  max-height: 90vh !important;
  height: 90vh !important;
}
</style>